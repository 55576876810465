import React from 'react'
import { Card, CardContent, Grid, Tooltip, Typography } from '@material-ui/core'
import copyToClipBoard from '../utils/copyToClipBoard'
const Fir = ({ data, handleAlertOpen, highlighted }) => {
  let { ident, rawPos, elapsedtime, frmg, dstn, time } = data
  return (
    <Card>
      <CardContent
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: highlighted ? '#bbb9b9e6' : '#fff',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={3}
            onClick={(e) => {
              e.preventDefault()
              copyToClipBoard(rawPos)
              handleAlertOpen('info', 'Coordinates Copied To Clipboard')
            }}
          >
            <Typography align="left" variant="body2">
              {rawPos}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">FIR BOUNDRY</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography align="left" variant="body2">
                  {ident}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {frmg}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  -
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography align="left" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {elapsedtime}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  --:--
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {dstn}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Fir
