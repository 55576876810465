import React from 'react'
import { Card, CardContent, Grid, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import copyToClipBoard from '../utils/copyToClipBoard'
import OffTime from './OffTime'
import OffFuel from './OffFuel'

const useStyles = makeStyles({
  origin: {
    backgroundColor: 'white',
  },
  content: {
    '&:last-child': { padding: 0 },
  },
})

const Origin = ({ data, style, handleOffTimeOrFuelBlur, offFuel, offTime }) => {
  let { ident } = data
  let { origin, content } = useStyles()
  return (
    <Card className={origin} id="origin">
      <CardContent className={content}>
        <Grid alignItems="center" container spacing={1}>
          <Grid item xs={4}>
            <OffTime
              offTime={offTime}
              handleOffTimeOrFuelBlur={handleOffTimeOrFuelBlur}
            />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">ORIGIN</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">{ident}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <OffFuel
              offFuel={offFuel}
              handleOffTimeOrFuelBlur={handleOffTimeOrFuelBlur}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Origin
