import React, { Component, createRef } from 'react'
import './styles.css'
import FixedLegend from './components/FixedLegend'
import VirtualScoreCard from './components/VirtualScoreCard'
import LoadingSpinner from './components/LoadingSpinner'
import CalculateScore from './utils/CalculateScore'
import NoData from './components/NoData'
import SwipeMenu from './components/SwipeMenu'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import MelDialog from './components/MelDialog'
import CrewEmailDialog from './components/CrewEmailDialog'
import HelpDialog from './components/HelpDialog'
import ScoreDialog from './components/ScoreDialog'
import ScrollToPosition from './components/ScrollToPosition'
import Alert from './components/Alert'


//import * as serviceWorker from "./service-worker";

import {
  AppBar,
  Typography,
  Grid,
  Toolbar,
  Divider,
  IconButton,
  Paper,
} from '@material-ui/core'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import MD5 from 'md5'

// override all moments to only dispaly in utc
import momentTz from 'moment-timezone'
import { isEvalSupported } from 'pdfjs-dist/build/pdf.worker'
import moment from 'moment'
momentTz.tz.setDefault('UTC')
// initalize pouchDB



class App extends Component {
  listRef = React.createRef()
  state = {
    data: {
      scorecard: [{ waypointType: 'noData' }],
      offTime: moment.utc(),
      raleaseTime: moment.utc(),
      offFuel: 0,
      currentLocation: { latitude: 0, longitude: 0 },
      positionIndex: 0,
    },

    openAlert: false,
    alert: { msg: null, severity: null },
    scoreDialog: false,
    settingsDialog: false,
    melDialog: false,
    crewEmailDialog: false,
    helpDialog: false,
    showMenu: false,
    showLoading: false,
    pointIndex: null,
  }

  componentDidMount() {
    this.restoreState()
    setInterval(this.timeCheck, 1000)
  }

  storeState = () => {
    try {
      localStorage.setItem('CurentRelease', JSON.stringify(this.state.data))
    } catch (error) {
      console.error(error)
    }
  }

  restoreState = () => {
    try {
      let state = JSON.parse(localStorage.getItem('CurentRelease'))
      if (state) {
        this.setState((prevState) => {
          return CalculateScore(prevState, { restoreState: state })
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  setScoreCard = (parsedData) => {
    this.setState(
      (prevState) => {
        console.log(parsedData)
        return CalculateScore(prevState, { parsedData: parsedData })
      },
      () => {
        this.storeState()
      },
    )
  }

  resetScoreCard = (e) => {
    this.setState((prev) => {
      prev.data.scorecard = [{ waypointType: 'noData' }]
      return prev
    })
  }

  handleOffTimeOrFuelBlur = (value, prop) => {
    let { offTime, offFuel } = this.state.data
    let newState = {
      offTime: prop === 'offTime' ? value : offTime,
      offFuel: prop === 'offFuel' ? value : offFuel,
    }
    this.setState(
      (prevState) => {
        return CalculateScore(prevState, newState)
      },
      () => {
        this.storeState()
      },
    )
  }

  handleScoreChange = (ata, afob, index) => {
    this.setState(
      (prevState) => {
        return CalculateScore(prevState, {
          index: index,
          ata: ata,
          afob: afob,
        })
      },
      () => {
        this.storeState()
      },
    )
  }

  toggleLoading = () => {
    this.setState({ showLoading: !this.state.showLoading })
  }

  timeCheck = () => {
    let {
      scorecard,
      positionIndex,
      releaseTime,
      destinationIndex,
      slotTime,
    } = this.state.data
    let curTime = moment(moment().format('HH:mm'), 'HH:mm').utc().clone()
    let newPositionIndex = scorecard.reduce((acc, waypoint, i, array) => {
      //console.log(curTime.isSameOrAfter(waypoint.rev), curTime, waypoint.rev)
      if (curTime.isSameOrAfter(waypoint.rev)) {
        acc = i
      }
      return acc
    }, -1)

    /*if (newPositionIndex < 2) {
      let releaseTimeFirstWarning = releaseTime.subtract(15, 'minutes').clone()
      if (curTime.isSame(releaseTimeFirstWarning)) {
        this.openAlert('warning', 'Caution Release Expires in 15 minutes')
      }

      let releaseTimeSecondWarning = releaseTime.subtract(5, 'minutes').clone()
      if (curTime.isSame(releaseTimeSecondWarning)) {
        this.openAlert('warning', 'Caution Release Expires in 5 minutes')
      }

      if (curTime.isAfter(releaseTime)) {
        this.openAlert('error', 'Warning Release Expired')
      }
    }

    if (
      scorecard[destinationIndex].rev.isAfter(slotTime.time) &&
      slotTime.type === 'arrival'
    ) {
      this.openAlert(
        'warning',
        `Warning Arrival Slot Time ${slotTime.time.format('HH:mm')}`,
      )
      console.log('Warning Slot Time')
    }*/

    if (newPositionIndex !== positionIndex) {
      this.setState((prevState) => {
        prevState.data.positionIndex = newPositionIndex
        return prevState
      })
    }
  }

  scrollToPoint = () => {
    let currentWaypoint = document.getElementById('scrollHere')
    if (currentWaypoint) {
      currentWaypoint.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }

  scrollToDestination = () => {
    document.getElementById('destination').scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  scrollToOrigin = () => {
    document.getElementById('origin').scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  saveScoreCard = () => {
    console.log('Implement Save Score Card')
  }

  openMelDialog = (state) => {
    this.setState({ melDialog: state, showMenu: false })
  }

  openCrewEmailDialog = (state) => {
    this.setState({ crewEmailDialog: state, showMenu: false })
  }

  openHelpDialog = (state) => {
    this.setState({ helpDialog: true, showMenu: false })
  }

  openScoreDialog = (index) => {
    this.setState({ pointIndex: index, scoreDialog: true })
  }

  closeDialog = (dialog) => {
    this.setState({ [dialog]: false })
  }

  openScoreCard = () => {
    console.log('implement open a scorecard from localStorage')
  }

  toggleMenu = (menuState) => {
    this.setState({ showMenu: !menuState })
  }

  handleAlertClose = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ openAlert: false })
  }

  handleAlertOpen = (severity, msg) => {
    let alert = { severity: severity, msg: msg }
    this.setState({ openAlert: true, alert: alert })
  }

  render() {
    let {
      offTime,
      offFuel,
      estLandingFuel,
      scorecard,
      positionIndex,
      flightNumber,
      origin,
      destination,
      tailNumber,
      melCdl,
      crewMembers,
    } = this.state.data

    let {
      showLoading,
      scoreDialog,
      pointIndex,
      melDialog,
      openAlert,
      helpDialog,
      crewEmailDialog,
    } = this.state

    let mainComponent = (
      <VirtualScoreCard
        handleOffTimeOrFuelBlur={this.handleOffTimeOrFuelBlur}
        openScoreDialog={this.openScoreDialog}
        positionIndex={positionIndex}
        rows={scorecard}
        listRef={this.listRef}
        offFuel={offFuel}
        offTime={offTime}
        estLandingFuel={estLandingFuel}
        handleAlertOpen={this.handleAlertOpen}
      />
    )

    if (scorecard[0]?.waypointType === 'noData') {
      mainComponent = (
        <NoData
          toggleLoading={this.toggleLoading}
          setScoreCard={this.setScoreCard}
          resetScoreCard={this.resetScoreCard}
        />
      )
    }

    return (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <LoadingSpinner open={showLoading} />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppBar position="fixed">
            <Toolbar style={{ paddingRight: 0 }}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <Typography onClick={this.scrollToOrigin}>
                    {origin}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    onClick={() => {
                      this.handleAlertOpen('info', 'helloworld')
                    }}
                  >
                    {flightNumber}
                  </Typography>
                  <Divider />
                  <Typography>{tailNumber}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography onClick={this.scrollToDestination}>
                    {destination}
                  </Typography>
                </Grid>
              </Grid>
              <IconButton
                onClick={() => {
                  this.toggleMenu(this.state.showMenu)
                }}
                style={{ float: 'right' }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <SwipeMenu
            {...this.state}
            toggleMenu={this.toggleMenu}
            toggleLoading={this.toggleLoading}
            setScoreCard={this.setScoreCard}
            resetScoreCard={this.resetScoreCard}
            melDialog={melDialog}
            openCrewEmailDialog={this.openCrewEmailDialog}
            openMelDialog={this.openMelDialog}
            openHelpDialog={this.openHelpDialog}
            handleAlertOpen={this.handleAlertOpen}
          />
          <FixedLegend />
          <Paper
            style={{
              paddingTop: 185,
              paddingLeft: 3,
              paddingRight: 3,
            }}
          >
            {mainComponent}
          </Paper>
          <ScoreDialog
            onClose={this.closeDialog}
            handleScoreChange={this.handleScoreChange}
            index={pointIndex}
            open={scoreDialog}
          />
          <CrewEmailDialog
            crewMembers={crewMembers}
            onClose={this.closeDialog}
            open={crewEmailDialog}
          />
          <MelDialog
            melCdl={melCdl}
            open={melDialog}
            onClose={this.closeDialog}
            handleAlertOpen={this.handleAlertOpen}
          />
          <HelpDialog
            helpDialog={helpDialog}
            open={helpDialog}
            onClose={this.closeDialog}
            scoreCardLength={scorecard.length}
          />
          <Alert
            openAlert={openAlert}
            msg={this.state.alert.msg}
            severity={this.state.alert.severity}
            handleClose={this.handleAlertClose}
          />
          <ScrollToPosition scrollToPoint={this.scrollToPoint} />
        </MuiPickersUtilsProvider>
      </div>
    )
  }
}

export default App
