const parseFlightPlanInfo = (releaseArray) => {
  // the following reduce function rmoves the page numbering annotations from the top and bottom
  // of each page
  releaseArray = releaseArray.reduce((acc, line) => {
    let { str } = line
    if (str.match(/KALITTA BRIEF PAGE \d+ OF \d+/)) {
      //console.log(str)
      return acc
    }
    if (str.match(/PAGE \d+ OF \d+/)) {
      //console.log(str)
      return acc
    }
    acc.push(line)
    return acc
  }, [])

  let startFlightPlanInfoIndex = releaseArray.findIndex((line, index) => {
    return line.str.includes('KALITTA AIR')
  })

  let endFlightPlanInfoIndex = releaseArray.findIndex((line, index) => {
    return line.str.includes(
      'CREW - LEAVE THIS FLIGHT RELEASE WITH GROUND PERSONNEL',
    )
  })

  if (startFlightPlanInfoIndex === -1 || endFlightPlanInfoIndex === -1) {
    return false
  }

  let flightPlanInfo = releaseArray.slice(
    startFlightPlanInfoIndex,
    endFlightPlanInfoIndex,
  )
  let test = makeFlightInfoObject(flightPlanInfo)
  return test
}

const makeFlightInfoObject = (flightPlanInfo) => {
  return flightPlanInfo.reduce(
    (acc, line, index, array) => {
      if (index === 2) {
        acc.tailNumber = getTailNumber(line)
        acc.flightDate = getFlightDate(line)
      }
      if (index === 5) {
        acc.shetd = getSheduledDepTime(line)
        acc.eta = getFlightEta(line)
        acc.flightNumber = getFlightNumber(line)
      }
      if (line.str.includes('ORIGIN') && index <= 20) {
        acc.origin = getOrigin(line)
      }
      if (line.str.includes('T/O ALT') && index <= 20) {
        acc.toAlt = getToAlt(line)
      }
      if (line.str.includes('INITIAL DEST') && index <= 20) {
        acc.initalDest = getInitDest(line)
      }
      if (line.str.includes('INITIAL ALTN') && index <= 20) {
        acc.initalAlt = getInitAlt(line)
      }
      if (line.str.includes('FINAL DEST') && index <= 20) {
        let dest = getFinalDest(line)
        acc.finalDest = dest
        acc.destination = dest
      }
      if (line.str.includes('FINAL ALTN') && index <= 20) {
        acc.finalAlt = getFinalAlt(line)
      }
      if (line.str.includes('ALTERNATE') && index <= 20) {
        acc.alternate = getAlt(line)
      }
      if (line.str.includes('DESTINATION') && index <= 20) {
        acc.destination = getDest(line)
      }
      if (
        line.str.includes(
          'PIC ........................................................',
        )
      ) {
        acc.jepFDRoute =
          acc.departureRuwnay +
          ' ' +
          getRoute(array, index) +
          ' ' +
          acc.arrivalRunway

      }
      if (line.str.includes('MEL/CDL')) {
        acc.melCdl = getMelCdls(array, index)
      }

      if (line.str.includes('*** APPROVED SLOT TIMES:')) {
        acc.slotTime = getSlotTime(line, acc.destination, acc.origin)
      }

      if (line.str.includes('RELEASE TIME')) {
        acc.releaseTime = getReleaseTime(line)
        acc.crewMembers = getcrewMembers(acc.crewMembers, line.str)
      }
      if (line.str.includes('PLANNED TO DEPT RUNWAY:')) {
        let dep = getDep(line)
        acc.departureRuwnay = dep.runway
        acc.sid = dep.sid
      }
      if (line.str.includes('PLANNED TO ARRV RUNWAY:')) {
        acc.arrivalRunway = getArr(line)
      }
      if (line.str.includes('PIC ') && index <= 25) {

        acc.crewMembers = getcrewMembers(acc.crewMembers, line.str)
      }
      if (line.str.includes('SIC/FO ') && index <= 25) {
        acc.crewMembers = getcrewMembers(acc.crewMembers, line.str)
      }
      if (line.str.includes('CAPT ') && index <= 25) {
        acc.crewMembers = getcrewMembers(acc.crewMembers, line.str)
      }
      if (line.str.includes('IRP ') && index <= 25) {
        acc.crewMembers = getcrewMembers(acc.crewMembers, line.str)
      }
      if (line.str.includes('ACM ') || line.str.includes('MX ') || line.str.includes('LM ') && index <= 25) {
        //split where there is at least 4 white spaces
        let split = line.str.split('     ')
        // grab the first and last elements and procces them as crewMember strings 
        acc.crewMembers = getcrewMembers(acc.crewMembers, split[0])
        acc.crewMembers = getcrewMembers(acc.crewMembers, split[split.length - 1])
      }

      if (line.str.includes('BURN    TIME   FL   DIST  WIND')) {
        acc = { ...acc, ...getFuelTable(array, index) }
      }
      return acc
    },
    { crewMembers: [] },
  )
}

const getTailNumber = ({ str }) => {
  return str.slice(23, 29)
}

const getFlightDate = ({ str }) => {
  return str.slice(53, 61)
}

const getSheduledDepTime = ({ str }) => {
  return str.slice(5, 11)
}

const getFlightEta = ({ str }) => {
  return str.slice(29, 34)
}

const getFlightNumber = ({ str }) => {
  return str.slice(53, 60)
}

const getOrigin = ({ str }) => {
  return str.slice(19, 23)
}

const getToAlt = ({ str }) => {
  return str.slice(61, 50)
}

const getInitDest = ({ str }) => {
  return str.slice(19, 23)
}

const getInitAlt = ({ str }) => {
  return str.slice(19, 23)
}

const getFinalDest = ({ str }) => {
  return str.slice(19, 23)
}
const getFinalAlt = ({ str }) => {
  return str.slice(19, 23)
}
const getAlt = ({ str }) => {
  return str.slice(19, 23)
}

const getDest = ({ str }) => {
  return str.slice(19, 23)
}

const getRoute = (releaseArray, start) => {
  start = start + 4
  let end = releaseArray.findIndex((line, index) => {
    return line.str.includes('-PBN')
  })
  end = end - 1
  let route = releaseArray.slice(start, end)
  route = route.reduce((acc, line) => {
    acc = acc + line.str
    return acc
  }, '')

  route = route.slice(11, route.length)

  route = route.replace(/(DCT)+/gm, '')
  const regex = /\/+[A-Z]\d+[A-Z]\d+/gm
  route = route.replace(regex, '')
  route = route.replace(/\s\s/gm, ' ')

  return route
}

const getMelCdls = (array, startIndex) => {
  let endIndex = array.findIndex((line) => {
    return line.str.includes('PASSED RAIM REQUIREMENTS FOR PRIMARY NAVIGATION')
  })
  return array
    .slice(startIndex, endIndex)
    .reduce((acc, { str }, index, array) => {
      if (str.startsWith('M ')) {
        // this is an MEL
        let mel = {
          type: 'mel',
          ata: str.slice(2, str.length),
          dmi: array[index + 1].str
            .slice(4, array[index + 1].str.length)
            .trim(),
          description: array[index + 2].str
            .slice(0, array[index + 2].str.length)
            .trim(),
        }
        acc.push(mel)
      }
      if (str.startsWith('C ') && !str.includes('COI')) {
        // this is a cdl
        let cdl = {
          type: 'cdl',
          ata: str.slice(2, str.length),
          dmi: array[index + 1].str
            .slice(4, array[index + 1].str.length)
            .trim(),
          description: array[index + 2].str
            .slice(0, array[index + 2].str.length)
            .trim(),
        }
        acc.push(cdl)
      }
      if (str.startsWith('C ') && str.includes('COI')) {
        // this a Carry over item
        let coi = { type: 'coi', cois: array[index + 2].str.trim() }
        acc.push(coi)
      }
      return acc
    }, [])
}

const getSlotTime = ({ str }, destination, origin) => {
  if (str.includes('+/-')) {
    return {
      type: str.slice(31, 35) === destination ? 'arrival' : 'departure',
      airport: str.slice(31, 35),
      time: str.slice(36, 40),
      window: str.slice(45, 47),
      hasSlotTime: true,
    }
  }
  if (str.includes('DEPARTURE')) {
    return {
      type: 'departure',
      airport: str.slice(31, 35),
      time: str.slice(46, 50),
      hasSlotTime: true,
    }
  }
  if (str.includes('ARRV')) {
    return { type: 'arrival', time: str.slice(37, 42), hasSlotTime: true }
  }

  return { hasSlotTime: false }
}

const getReleaseTime = ({ str }) => {
  return str.slice(13, 17).trim()
}

const getDep = ({ str }) => {
  str = str.trim().slice(23, 9999).trim()
  str = str.replace('RADAR', '')
  str = str.replace('EXPECT', '')
  str = str.replace('VECTORS', '')
  str = str.replace('DCT', '')
  str = str.replace(/-/g, '')
  str = str.replace('(', '')
  str = str.replace('CHINA', '')
  str = str.replace('ASSIGNED', '')
  str = str.replace('SID', '')
  str = str.replace(')', '')
  str = str.replace('.', '')
  str = str.trim().split(' ').reduce((acc, item) => {
    if (item !== "") {
      acc.push(item)
    } return acc
  }, [])
  return {
    runway: str[0],
    sid: str[1],
    trans: str[2]
  }
}

const getArr = ({ str }) => {
  return str.trim().slice(23, 27).trim()
}

const getFuelTable = (releaseArray, startIndex) => {
  let endIndex =
    releaseArray.findIndex((line, index) => {
      return line.str.includes('EST LANDING FUEL:')
    }) + 1

  let fuelTable = releaseArray.slice(startIndex, endIndex)

  endIndex = fuelTable.length - 1
  return {
    payload: Number(fuelTable[2].str.slice(62, 69).trim()),
    zfw: Number(fuelTable[3].str.slice(62, 69).trim()),
    takeoffFuel: Number(fuelTable[4].str.slice(62, 69).trim()),
    estFuelBurn: Number(fuelTable[6].str.slice(62, 69).trim()),
    estLandingWeight: Number(fuelTable[7].str.slice(62, 69).trim()),
    estLandingFuel: Number(fuelTable[endIndex].str.slice(62, 69).trim()),
  }
}

const getcrewMembers = (crewMembers, crewMemberString) => {
  // {first, last, position, emp#, email}
  let array = []
  let crewMember
  if (crewMemberString.includes('FLIGHT FOLLOWER')) {
    array = crewMemberString.trim().split(' ')
    crewMember = {
      firstI: array[6].slice(0, 1),
      last: array[7],
      position: 'FF',
      email: array[6] + array[7] + '@kalittaair.com',
    }
    crewMembers.push(crewMember)
  } else {
    array = crewMemberString
      .trim()
      .split(' ')
      .reduce((acc, item) => {
        if (item !== '') {
          acc.push(item)
        }
        return acc
      }, [])
    if (array.length < 2) {
      return crewMembers
    }
    let last = array[2] === 'LA' ? 'LAFONTAINE' : array[2]
    crewMember = {
      firstI: array[array.length - 1],
      last: last,
      position: array[1],
      emp: array[0],
      email: array[array.length - 1] + last + '@kalittaair.com',
    }
    crewMembers.push(crewMember)

  }
  //console.log(crewMembers)
  return crewMembers
}

export default parseFlightPlanInfo
