import React from 'react'
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  ListItemText,
} from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish'
import FileInput from './FileInput'
import TimelineIcon from '@material-ui/icons/Timeline'
import LocalAirportIcon from '@material-ui/icons/LocalAirport'
import InfoIcon from '@material-ui/icons/Info'
import SaveIcon from '@material-ui/icons/Save'
import SettingsIcon from '@material-ui/icons/Settings'
import ShareIcon from '@material-ui/icons/Share'
import BuildIcon from '@material-ui/icons/Build'
import copyToClipBoard from '../utils/copyToClipBoard'
import EmailIcon from '@material-ui/icons/Email'

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const SwipeMenu = ({
  showMenu,
  toggleMenu,
  toggleLoading,
  setScoreCard,
  resetScoreCard,
  data,
  openMelDialog,
  openCrewEmailDialog,
  openHelpDialog,
  handleAlertOpen,
}) => {
  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor={'right'}
      open={showMenu}
      onClose={() => {
        toggleMenu(true)
      }}
      onOpen={() => {
        toggleMenu(false)
      }}
    >
      <List>
        <Divider />
        <ListItem
          button
          onClick={() => {
            CopyJepRoute(data.jepFDRoute)
          }}
        >
          <ListItemIcon>
            <TimelineIcon />
          </ListItemIcon>
          <ListItemText>Copy Route</ListItemText>
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={() => {
            CopyAlternates(data)
          }}
        >
          <ListItemIcon>
            <LocalAirportIcon />
          </ListItemIcon>
          <ListItemText>Copy Alternates</ListItemText>
        </ListItem>
        <Divider />
        {
          // removing this feature 
        //<ListItem button onClick={openCrewEmailDialog}>
          //<ListItemIcon>
           // <EmailIcon />
          //</ListItemIcon>
          //<ListItemText>Crew Communication</ListItemText>
        //</ListItem>
      }
        <Divider />
        <ListItem button onClick={openMelDialog}>
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText>MEL's</ListItemText>
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={() => {
            document.getElementById('file').click()
          }}
        >
          <ListItemIcon>
            <PublishIcon />
            <FileInput
              toggleMenu={toggleMenu}
              test={'This is a test'}
              toggleLoading={toggleLoading}
              setScoreCard={setScoreCard}
              resetScoreCard={resetScoreCard}
            />
          </ListItemIcon>
          <ListItemText>Parse Release</ListItemText>
        </ListItem>
        <Divider />
        <ListItem button onClick={openHelpDialog}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>About</ListItemText>
        </ListItem>
        <ListItem button onClick={shareApp}>
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          <ListItemText>Share This App</ListItemText>
        </ListItem>
      </List>
    </SwipeableDrawer>
  )
}

const CopyAlternates = (data) => {
  let {
    initalDest,
    initalAlt,
    finalAlt,
    toAlt,
    alternate,
    allNormalEtp,
    depressedEtp,
    engineOutEtp,
  } = data
  engineOutEtp = engineOutEtp?.before + ' ' + engineOutEtp?.after
  initalDest = initalDest ? initalDest : ''
  alternate = alternate ? alternate : ''
  initalAlt = initalAlt ? initalAlt : ''
  finalAlt = finalAlt ? finalAlt : ''
  toAlt = toAlt !== 'NIL' ? toAlt : ''
  let alternates = (
    toAlt +
    ' ' +
    initalDest +
    ' ' +
    initalAlt +
    ' ' +
    finalAlt +
    ' ' +
    alternate +
    ' ' +
    engineOutEtp
  ).trim()
  copyToClipBoard(alternates)
}

const CopyJepRoute = (jepFDRoute) => {
  copyToClipBoard(jepFDRoute)
}

const shareApp = () => {
  const shareData = {
    title: 'Super Score',
    text: 'This makes life EASY!!!!!',
    url: 'https://superscore.flightstat.net',
  }
  try {
    navigator.share(shareData)
    console.log('Share succesfull')
  } catch (err) {
    console.log('Error: ' + err)
  }
}

export default SwipeMenu
