import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish'
import FileInput from './FileInput'
const NoData = (props) => {
  let { toggleLoading, setScoreCard, resetScoreCard } = props
  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justify="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <Grid item xs={1}>
        <div onClick={ParseFile}>
          <PublishIcon style={{ width: '100%', height: '100%' }} />
          <FileInput
            toggleLoading={toggleLoading}
            setScoreCard={setScoreCard}
            resetScoreCard={resetScoreCard}
          />
        </div>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h4">No Data</Typography>
      </Grid>
    </Grid>
  )
}

const ParseFile = () => {
  document.getElementById('file').click()
}

export default NoData
