import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
class OffFuel extends Component {
  state = { offFuel: 0 }

  componentDidMount() {
    this.setState({ offFuel: this.props.offFuel })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.offFuel !== prevProps.offFuel) {
      this.setState({ offFuel: this.props.offFuel })
    }
  }

  onChange = (e) => {
    let { value } = e.target
    this.setState({ offFuel: value })
  }
  onBlur = (e) => {
    let { offFuel } = this.state
    this.props.handleOffTimeOrFuelBlur(offFuel, 'offFuel')
  }
  render() {
    return (
      <TextField
        type="number"
        InputProps={{ inputProps: { min: 0, step: 0.1 } }}
        value={this.state.offFuel}
        onChange={this.onChange}
        placeholder="Off Fuel"
        onBlur={this.onBlur}
        label="Off Fuel"
        size="small"
        variant="outlined"
      />
    )
  }
}

export default OffFuel
