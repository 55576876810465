import React from 'react'
import { Card, CardContent, Grid, Typography, Hidden } from '@material-ui/core'
import copyToClipBoard from '../utils/copyToClipBoard'
import moment from 'moment'
const Etp = ({ data, highlighted, handleAlertOpen }) => {
  let {
    rawPos,
    note,
    airportBefore,
    airportAfter,
    eta,
    tbo,
    rev,
    elapsedtime,
  } = data

  return (
    <Card>
      <CardContent
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: highlighted ? '#bbb9b9e6' : '#fff',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={2}
            onClick={(e) => {
              e.preventDefault()
              copyToClipBoard(rawPos)
              handleAlertOpen('info', 'Coordinates Copied To Clipboard')
            }}
          >
            <Typography align="left" variant="body2">
              {rawPos}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">ETP</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            onClick={(e) => {
              e.preventDefault()
              copyToClipBoard(airportBefore)
              handleAlertOpen('info', 'Airport Copied To Clipboard')
            }}
          >
            <Typography variant="body2">{airportBefore}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">{note}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              onClick={(e) => {
                e.preventDefault()
                copyToClipBoard(airportAfter)
                handleAlertOpen('info', 'Airport Copied To Clipboard')
              }}
            >
              {airportAfter}
            </Typography>
          </Grid>
          <Grid item item xs={3}>
            <Typography variant="body2">{elapsedtime}</Typography>
          </Grid>
          <Grid item item xs={3}>
            <Typography variant="body2">
              {moment.isMoment(eta) ? eta.format('HH:mm') : eta}
            </Typography>
          </Grid>
          <Grid item item xs={3}>
            <Typography variant="body2">
              {' '}
              {moment.isMoment(eta) ? eta.format('HH:mm') : rev}
            </Typography>
          </Grid>
          <Grid item item xs={3}>
            <Typography variant="body2">{tbo}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Etp
