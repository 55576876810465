import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
const Alert = ({ openAlert, msg, severity, handleClose }) => {
  return (
    <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose}>
      <MAlert onClose={handleClose} severity={severity}>
        {msg}
      </MAlert>
    </Snackbar>
  )
}

function MAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default Alert
