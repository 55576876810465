import React, { Component } from 'react'
import { KeyboardTimePicker } from '@material-ui/pickers'
import moment from 'moment'
import {
  TextField,
  DialogTitle,
  Dialog,
  DialogActions,
  Button,
} from '@material-ui/core'

class ScoreDialog extends Component {
  state = { afob: '', ata: moment() }
  onFuelChange = (e) => {
    let { value } = e.target
    this.setState({ afob: value })
  }
  onTimeChange = (time) => {
    if (time) {
      this.setState({ ata: time })
    }
  }

  handleAccept = (e) => {
    this.props.handleScoreChange(
      this.state.ata,
      this.state.afob,
      this.props.index,
    )
    this.handleClose()
    e.preventDefault()
  }

  handleClose = () => {
    this.props.onClose('scoreDialog')
  }

  handelClear = (e) => {
    this.setState({ afob: 0 })
    this.props.handleScoreChange('--:--', '--:--', this.props.index)
    this.handleClose()
    e.preventDefault()
  }

  render() {
    return (
      <Dialog
        aria-labelledby="simple-dialog-title"
        onClose={this.handleClose}
        open={this.props.open}
        ref={this.props.ref}
      >
        <DialogTitle id="simple-dialog-title">Fuel Check</DialogTitle>
        <KeyboardTimePicker
          ref={this.props.ref}
          style={{ width: '90%', padding: 10 }}
          ampm={false}
          label="ATA"
          clearable
          inputVariant="outlined"
          value={this.state.ata}
          onChange={this.onTimeChange}
        />
        <TextField
          label="AFOB"
          type="number"
          InputProps={{
            inputProps: { min: 0, step: 0.1, ref: this.props.ref },
          }}
          value={this.state.afob}
          onChange={this.onFuelChange}
          margin="dense"
          variant="outlined"
          style={{ width: '90%', padding: 10 }}
        />
        <DialogActions>
          <Button onClick={this.handelClear} color="primary">
            Clear
          </Button>
          <Button onClick={this.handleAccept} color="primary" autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ScoreDialog
