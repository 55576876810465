import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Grid, Typography, Hidden } from '@material-ui/core'
import moment from 'moment'
import copyToClipBoard from '../utils/copyToClipBoard'

const useStyles = makeStyles({
  waypoint: {
    backgroundColor: 'white',
  },
  content: {
    '&:last-child': { padding: 5 },
  },
})

const Waypoint = ({
  data,
  highlighted,
  offTime,
  offFuel,
  openScoreDialog,
  index,
  estLandingFuel,
  handleAlertOpen,
  scrollPoint,
}) => {
  let { waypoint, content } = useStyles()

  let {
    rawPos,
    ident,
    frq,
    dist,
    dtgo,
    mc,
    mh,
    fl,
    wind,
    ws,
    cmp,
    oat,
    tas,
    gs,
    time,
    elapsedtime,
    ata,
    eta,
    rev,
    rem,
    tbo,
    abo,
    frmg,
    afob,
    efb,
    dstn,
    clf,
    burn,
    tanks,
    deltaT,
    reclearFuel,
    reclear,
  } = data

  let reclearStyle = reclear ? {} : { display: 'none' }

  let GREY = '#bbb9b9e6'

  return (
    <Card id={scrollPoint}>
      <CardContent
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: highlighted ? GREY : '#fff',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            onClick={(e) => {
              e.preventDefault()
              copyToClipBoard(rawPos)
              handleAlertOpen('info', 'Coordinates Copied To Clipboard')
            }}
          >
            <Typography align="left" variant="body2">
              {rawPos}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Grid container spacing={2}>
              <Grid
                onClick={(e) => {
                  e.preventDefault()
                  copyToClipBoard(ident)
                  handleAlertOpen('info', 'Waypoint Copied To Clipboard')
                }}
                item
                xs={1}
              >
                <Typography align="left" variant="body2">
                  {ident}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {dist}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {mc}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {fl}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {wind}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {cmp}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {tas}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {time}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {moment.isMoment(eta) ? eta.format('HH:mm') : eta}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                onClick={(e) => {
                  openScoreDialog(index)
                  e.preventDefault()
                }}
              >
                <Typography align="center" variant="body2">
                  {moment.isMoment(ata) ? ata.format('HH:mm') : ata}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                onClick={(e) => {
                  openScoreDialog(index)
                  e.preventDefault()
                }}
              >
                <Typography align="center" variant="body2">
                  {tbo}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                onClick={(e) => {
                  openScoreDialog(index)
                  e.preventDefault()
                }}
              >
                <Typography align="center" variant="body2">
                  {frmg}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  -
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography align="left" variant="body2">
                  {frq}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {dtgo}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {mh}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2"></Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {ws}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {oat}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {gs}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {elapsedtime}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {moment.isMoment(rev) ? rev.format('HH:mm') : rev}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                onClick={(e) => {
                  openScoreDialog(index)
                  e.preventDefault()
                }}
              >
                <Typography align="center" variant="body2">
                  {rem}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                onClick={(e) => {
                  openScoreDialog(index)
                  e.preventDefault()
                }}
              >
                <Typography align="center" variant="body2">
                  {abo}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                onClick={(e) => {
                  openScoreDialog(index)
                  e.preventDefault()
                }}
              >
                <Typography align="center" variant="body2">
                  {afob}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  {dstn}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item xs={8}>
                <Typography style={reclearStyle} align="center" variant="body2">
                  RECLEARANCE POINT MINIMUM FUEL REQD: {reclearFuel}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" variant="body2">
                  &nbsp;
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <TimeDiff deltaT={deltaT} />
              </Grid>
              <Grid item xs={1}>
                <Burn burn={burn} />
              </Grid>
              <Grid item xs={1}>
                <Tanks tanks={tanks} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <LandingFuel clf={clf} estLandingFuel={estLandingFuel} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const Green = '#118a00'
const Red = '#d60b04'

const Burn = ({ burn }) => {
  if (burn) {
    let style = Math.sign(burn) === -1 ? { color: Red } : { color: Green }
    burn = Math.sign(burn) === -1 ? burn : '+' + Math.abs(burn)
    return (
      <Typography variant="button" style={style}>
        {burn}
      </Typography>
    )
  }
  return <Typography variant="button">0</Typography>
}

const Tanks = ({ tanks }) => {
  if (tanks) {
    let style = Math.sign(tanks) === 1 ? { color: Red } : { color: Green }
    tanks =
      Math.sign(tanks) === 1
        ? '-' + tanks
        : '+' + Math.abs(tanks).toPrecision(2)
    return (
      <Typography variant="button" style={style}>
        {tanks}
      </Typography>
    )
  }
  return <Typography variant="button">0</Typography>
}

const TimeDiff = ({ deltaT }) => {
  if (deltaT) {
    let style = Math.sign(deltaT) === -1 ? { color: Red } : { color: Green }
    deltaT = Math.sign(deltaT) === 1 ? '-' + deltaT : '+' + Math.abs(deltaT)
    return (
      <Typography variant="button" style={style}>
        {deltaT}
      </Typography>
    )
  }
  return <Typography variant="button">0</Typography>
}

const LandingFuel = ({ clf, estLandingFuel }) => {
  let style
  if (clf === 0) {
    style = { color: 'black' }
  } else {
    style = clf < estLandingFuel / 1000 ? { color: Red } : { color: Green }
  }
  return (
    <Typography variant="button" style={style}>
      {clf}
    </Typography>
  )
}
export default Waypoint
