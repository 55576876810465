function copyToClipboard(textToCopy) {
  var textArea;

  function isIOS() {
    //can use a better detection logic here
   
    return navigator.userAgent.includes('Mac')
  }

  function createTextArea(text) {
    textArea = document.createElement('textArea');
    textArea.readOnly = true;
    textArea.contentEditable = true;
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    var range, selection;


      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    
  }

  function copyTo() {
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  if(isIOS()){
  createTextArea(textToCopy);
  selectText();
  copyTo();
} else {
  navigator.clipboard.writeText(textToCopy)
}
}

export default copyToClipboard
