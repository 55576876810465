import React, { Component } from 'react'
import HandelFileChange from '../utils/HandelFileChange'

class FileInput extends Component {
  render() {
    let { setScoreCard, toggleLoading, toggleMenu } = this.props

    return (
      <input
        accept="application/pdf"
        style={{ display: 'none' }}
        id="file"
        multiple
        onChange={(e) => {
          toggleLoading()
          HandelFileChange(e, setScoreCard)
          toggleMenu(true)
          toggleLoading()
        }}
        type="file"
      />
    )
  }
}

export default FileInput
