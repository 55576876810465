import React from 'react'
import copyToClipBoard from '../utils/copyToClipBoard'
import {
  DialogTitle,
  Grid,
  Dialog,
  DialogActions,
  Button,
  List,
  ListItem,
  Divider,
  Typography,
} from '@material-ui/core'

const MelDialog = ({
  melCdl,
  onClose,
  open,
  ref,
  toggleMenu,
  handleAlertOpen,
}) => {
  let mels = melCdl ? (
    melCdl.map((mel, i) => {
      let { type, ata, dmi, description, cois } = mel
      if (type === 'mel') {
        return (
          <div key={i}>
            <ListItem
              button
              onClick={(e) => {
                e.preventDefault()
                copyToClipBoard(mel.ata)
                handleAlertOpen('info', 'ATA# Copied To Clipboard')
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={1}>
                  <Typography variant="button">MEL</Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography variant="button">Description</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="button" style={{ float: 'right' }}>
                      {description}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="button">ATA# </Typography>
                    <Typography variant="button">{ata}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="button" style={{ float: 'right' }}>
                      DMI# {dmi}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
          </div>
        )
      }
      if (type === 'coi') {
        return (
          <ListItem key={i}>
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <Typography variant="button">Coi's</Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="button" style={{ float: 'right' }}>
                  {cois}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        )
      }
    })
  ) : (
    <ListItem key="none">None</ListItem>
  )

  return (
    <Dialog
      aria-labelledby="MEL's"
      onClose={() => {
        onClose('melDialog')
      }}
      open={open}
      ref={ref}
    >
      <List>{mels}</List>
      <DialogActions>
        <Button
          onClick={() => {
            onClose('melDialog')
          }}
          color="primary"
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MelDialog
