import Coordinates from 'coordinate-parser'
import ScoreCard from '../components/VirtualScoreCard'
let destinationIndex = -1
const scoreCardParser = (releaseArray, etps, fltPlanInfo) => {
  let startOfScoreCardIndex = releaseArray.findIndex((line, index) => {
    return line.str.includes('IDENT')
  })
  startOfScoreCardIndex = startOfScoreCardIndex + 3

  let endOfScoreCardIndex = releaseArray.findIndex((line, index) => {
    return line.str.includes('WAYPOINT SUMMARY')
  })

  let scoreCard = releaseArray.slice(startOfScoreCardIndex, endOfScoreCardIndex)

  let originCords = releaseArray[startOfScoreCardIndex - 1].str
    .slice(10, 29)
    .replace('/', ' ')
  scoreCard = scoreCard.reduce((acc, line) => {
    if (
      !line.str.includes('FIR->') &&
      //!line.str.includes("-ETP1") &&
      //!line.str.includes('RECLEARANCE POINT MINIMUM FUEL REQD:') &&
      !line.str.includes('RERELEASE TIME/FLIGHT FOLLOWER') &&
      !line.str.includes(
        '    -----------------------  ALTERNATE  ---------------------',
      ) &&
      !line.str.includes('PAGE') &&
      !line.str.includes('IDENT  DIST') &&
      !line.str.includes('FRQ    DTGO MH      W/S    OAT  G/S') &&
      !line.str.includes('- FL -')
    ) {
      acc.push(line)
    }
    return acc
  }, [])


  let scorecard = scoreCard.reduce((acc, line, index, array) => {
    let point = {}
    //This if statement creates FIR boundries and gps waypoints and VORs/NDBs
    if (line.str.length === 18) {
      point = {
        ...getPointCoord(line.str),
        ...getFirstLine(array[index + 1].str, fltPlanInfo, acc.length + 1),
        ...getSecondLine(array[index + 2].str),
        passed: false,
      }
      //check if the point is part of a b44 refile reclearence

      if (
        array[index + 3]?.str.includes('RECLEARANCE POINT  MINIMUM FUEL REQD:')
      ) {
        point.reclearFuel =
          Number(
            array[index + 3].str.slice(
              array[index + 3].str.length - 5,
              array[index + 3].str.length,
            ),
          ) / 10
        point.reclear = true
      }
      acc.push(point)
    }

    // this if statement creates TOC and TOD
    if (line.str.includes('TOC') || line.str.includes('TOD')) {
      point = {
        ...getFirstLine(line.str, fltPlanInfo),
        ...getSecondLine(array[index + 1].str),
        passed: false,
      }
      acc.push(point)
    }
    // This if statement creates ETPs
    if (line.str.includes('-ETP1')) {


      let note, airportBefore, airportAfter, tbo, time, levelOff, gw

      if (etps.allNormalEtp.position === line.str.slice(0, 18)) {
        note = 'M86 ALL ENGINES/ ALL NORMAL'
        airportBefore = etps.allNormalEtp.before
        airportAfter = etps.allNormalEtp.after
        time = etps.allNormalEtp.time
        tbo = etps.allNormalEtp.burnOff
        levelOff = etps.allNormalEtp.levelOff
        gw = etps.allNormalEtp.gw

        point = {
          ident: 'ETP',
          waypointType: 'ETP',
          note: note,
          passed: false,
          airportBefore: airportBefore,
          airportAfter: airportAfter,
          elapsedtime: time || '',
          tbo: tbo,
          ata: '--:--',
          levelOff: levelOff,
          gw: gw,
          ...getPointCoord(line.str.slice(0, 18)),
        }
        acc.push(point)

      }

      if (etps.depressedEtp.position === line.str.slice(0, 18)) {
        note = 'LRC ALL ENGINES DEPRESSURIZED'
        airportBefore = etps.depressedEtp.before
        airportAfter = etps.depressedEtp.after
        time = etps.depressedEtp.time
        tbo = etps.depressedEtp.burnOff
        levelOff = etps.depressedEtp.levelOff
        gw = etps.depressedEtp.gw

        point = {
          ident: 'ETP',
          waypointType: 'ETP',
          note: note,
          passed: false,
          airportBefore: airportBefore,
          airportAfter: airportAfter,
          elapsedtime: time || '',
          tbo: tbo,
          ata: '--:--',
          levelOff: levelOff,
          gw: gw,
          ...getPointCoord(line.str.slice(0, 18)),
        }
        acc.push(point)

      }


      if (etps.engineOutEtp.position === line.str.slice(0, 18)) {
        note = '2EO DRIFTDOWN LRC'
        airportBefore = etps.engineOutEtp.before
        airportAfter = etps.engineOutEtp.after
        time = etps.engineOutEtp.time
        tbo = etps.engineOutEtp.burnOff
        levelOff = etps.engineOutEtp.levelOff
        gw = etps.engineOutEtp.gw

        point = {
          ident: 'ETP',
          waypointType: 'ETP',
          note: note,
          passed: false,
          airportBefore: airportBefore,
          airportAfter: airportAfter,
          elapsedtime: time || '',
          tbo: tbo,
          ata: '--:--',
          levelOff: levelOff,
          gw: gw,
          ...getPointCoord(line.str.slice(0, 18)),
        }
        acc.push(point)
      }


    }
    return acc
  }, [])
  // create the origin
  scorecard.unshift({
    ident: fltPlanInfo.origin,
    waypointType: 'origin',
    ...getPointCoord(originCords),
  })
  // create the alternate
  scorecard[scorecard.length - 1].waypointType = 'alternate'

  return { scorecard: scorecard, destinationIndex: destinationIndex }
}

const getPointCoord = (string) => {
  try {
    let position = new Coordinates(string)
    let latitude = position.getLatitude()
    let longitude = position.getLongitude()
    return { latitude: latitude, longitude: longitude, rawPos: string }
  } catch (e) {
    console.error(
      'One coordinate string failed to parse properly string was  ' + string,
      e,
    )
  }
}

const getFirstLine = (string, fltPlanInfo, index) => {
  // determines what type of point is beign created
  let ident = string.slice(0, 6).trim()
  let waypointType = 'waypoint'
  if (ident.includes('-')) {
    waypointType = 'fir'
  }
  if (ident.trim() === fltPlanInfo.destination.trim()) {
    waypointType = 'destination'
    destinationIndex = index
  }
  //console.log(string)
  return {
    ident: string.slice(0, 6).trim(),
    dist: Number(string.slice(7, 11).trim()),
    mc: Number(string.slice(12, 15).trim()),
    fl: string.slice(15, 19).trim(),
    wind: string.slice(20, 26).trim(),
    cmp: string.slice(27, 31).trim(),
    tasmac: Number(string.slice(32, 35).trim()),
    time: Number(string.slice(40, 43).trim()),
    eta: '00:00',
    ata: '--:--',
    tbo: Number(string.slice(54, 58).trim()) / 10,
    frmg: Number(string.slice(59, 63).trim()) / 10,
    efb: 0,
    waypointType: waypointType,
  }
}

const getSecondLine = (string) => {
  // console.log(string)
  return {
    frq: string.slice(0, 5).trim(),
    dtgo: Number(string.slice(7, 11).trim()),
    mh: Number(string.slice(12, 15).trim()),
    ws: string.slice(20, 23).trim(),
    oat: string.slice(27, 31).trim(),
    gs: string.slice(33, 36).trim(),
    elapsedtime: string.slice(40, 46).replace('.', ':').trim(),
    rev: '--:--',
    rem: 0,
    abo: 0,
    burn: 0,
    afob: '--:--',
    clf: 0,
    diffElfClf: 0,
    tanks: 0,
    dstn: Number(string.slice(64, 69).trim()) / 10,
  }
}

export default scoreCardParser
