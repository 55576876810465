import React, { Component } from 'react'
import { KeyboardTimePicker } from '@material-ui/pickers'
import moment from 'moment'
import {
  List,
  ListItem,
  DialogTitle,
  Dialog,
  DialogActions,
  Button,
  Checkbox,
  Grid,
  Typography,
  Link,
} from '@material-ui/core'

class CrewEmailDialog extends Component {
  state = {
    emailString: '',
  }

  createEmail = (e) => {
    window.location.href = `mailto:${this.state.emailString}`
    this.handleClose()
  }

  emailMe = (e) => {
    window.location.href = `mailto:cgrimes@kalittaair.com`
    this.handleClose()
  }

  handleClose = () => {
    this.setState({ emailString: '' })
    this.props.onClose('crewEmailDialog')
  }

  handleCheckChange = (email) => {
    email = email.toLowerCase()
    this.setState((prev) => {
      prev.emailString = prev.emailString + email + ','
      return prev
    })
  }

  render() {
    let { crewMembers } = this.props
    let crewList = []
    if (crewMembers) {
      crewList = crewMembers.map((crewMember, i) => {
        return (
          <ListItem key={i}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={10}
              >{`${crewMember.position}:  ${crewMember.email}`}</Grid>
              <Grid item xs={2}>
                <Checkbox
                  onChange={() => {
                    this.handleCheckChange(crewMember.email)
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>
            </Grid>
          </ListItem>
        )
      })
    }

    return (
      <Dialog
        aria-labelledby="simple-dialog-title"
        onClose={this.handleClose}
        open={this.props.open}
        ref={this.props.ref}
      >
        <DialogTitle id="simple-dialog-title">Select Recipients</DialogTitle>
        <List>{crewList}</List>
        <DialogActions justifyContent="center">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                NOTE: company emails not in the format of "First Initial Last
                Name" will not generate properly. If you have a comapny email
                not in this format, <Link onClick={this.emailMe}>email me</Link>{' '}
                and I will add an exception so that it will generate properly.
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={5}>
              <Button
                onClick={this.createEmail}
                color="primary"
                variant="contained"
                autoFocus
              >
                Generate Email
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default CrewEmailDialog
