import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import NearMeIcon from '@material-ui/icons/NearMe'
const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
}))

const ScrollToPosition = ({ scrollToPoint }) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Fab onClick={scrollToPoint} className={classes.fab}>
      <NearMeIcon />
    </Fab>
  )
}

export default ScrollToPosition
