import parseScoreCard from './ScoreCardParser'
import parseFlightPlanInfo from './ParseFlightPlanInfo'
import EtpParser from './EtpParser'
import moment from 'moment'

const releaseParser = (releaseArray) => {
  let flightPlanInfo = parseFlightPlanInfo(releaseArray)
  if (flightPlanInfo) {
    let etps = EtpParser(releaseArray)
    return {
      ...parseScoreCard(releaseArray, etps, flightPlanInfo),
      ...flightPlanInfo,
      ...etps,
      timeIndex: 0,
    }
  }
  return {
    scorecard: [],
    offTime: moment(),
    offFuel: 0,
    currentLocation: { latitude: 0, longitude: 0 },
    positionIndex: -1,
  }
}

export default releaseParser
