import moment from 'moment'

const CalculateScore = (prevState, newState) => {
  let { scorecard, destinationIndex } = prevState.data
  console.log('Calculate Score is running')
  if (newState.hasOwnProperty('parsedData')) {
    let { parsedData } = newState

    prevState.data = { ...prevState.data, ...parsedData }
    prevState.data.scorecard = parsedData.scorecard
    //parse the shetd turn it into a moment and add two hours to get ralease validity
    //take the slot time info and parse it into a moment
    prevState.data.releaseTime = moment(parsedData.shetd, 'HH.mm')
      .add(2, 'hours')
      .clone()

    if (parsedData.hasOwnProperty('slotTime')) {
      prevState.data.slotTime.time = moment(parsedData.slotTime.time, 'HH:mm')
    }

    prevState.data.positionIndex = 0
    prevState.data.offTime = moment(parsedData.shetd, 'HH.mm')
    prevState.data.offFuel = (parsedData.takeoffFuel / 1000).toFixed(1)
    prevState.showLoading = false
    scorecard = parsedData.scorecard
  }

  // restores the state from what was retunred from local storage
  if (newState.hasOwnProperty('restoreState')) {
    let state = newState.restoreState
    state.scorecard = state.scorecard.map((waypoint) => {
      waypoint.eta = moment(waypoint.eta)
      waypoint.rev = moment(waypoint.rev)
      if (waypoint.ata !== '--:--') {
        waypoint.ata = moment(waypoint.ata)
      }
      return waypoint
    })
    state.releaseTime = moment(state.releaseTime)
    state.offTime = moment(
      moment(state.offTime).format('HH:mm'),
      'HH:mm',
    ).clone()
    state.slotTime.time = moment(
      moment(state.slotTime.time).format('HH:mm'),
      'HH:mm',
    ).clone()
    state.showLoading = false
    prevState.data = state
    return prevState
  }

  if (newState.hasOwnProperty('offTime')) {
    prevState.data.offTime = newState.offTime
    prevState.data.positionIndex = 0
  }

  if (newState.hasOwnProperty('offFuel')) {
    prevState.data.offFuel = newState.offFuel
  }

  if (newState.hasOwnProperty('index')) {
    let { index, afob, ata } = newState
    scorecard[index].ata = ata
    scorecard[index].rev = ata
    scorecard[index].afob = afob
    // scorecard[destinationIndex].rev = scorecard[destinationIndex].eta
  }

  prevState.data.scorecard = scorecard.map((waypoint, i, card) => {
    let { tbo, abo, frmg, afob, dstn, clf, ata, elapsedtime } = waypoint

    let { estLandingFuel, offFuel } = prevState.data

    let eta

    if (elapsedtime) {
      eta = moment(prevState.data.offTime)
        //  .utc(true)
        .add(moment.duration(elapsedtime.trim()))
        .clone()
    }

    let rev
    if (i === 1) {
      rev = eta
    }

    if (i > 1 && i < card.length) {
      rev = moment(card[i - 1].rev)
        .add(waypoint.time, 'minutes')
        .clone()
    }

    if (waypoint.ata === '--:--') {
      //console.log(rev.format('HH:mm'))
      waypoint.eta = eta
      waypoint.rev = rev
      waypoint.ata = '--:--'
      waypoint.afob = '--:--'
      waypoint.deltaT = 0
      waypoint.rem = 0
      waypoint.burn = 0
      waypoint.tanks = 0
      waypoint.clf = 0
      waypoint.diffElfClf = 0
      return waypoint
    }

    if (waypoint.ata !== '--:--' && waypoint.waypointType === 'waypoint') {
      waypoint.eta = eta

      waypoint.deltaT = Math.round(
        moment
          .duration(
            moment(eta.format('HH:mm'), 'HH:mm').diff(
              moment(ata.format('HH:mm'), 'HH:mm'),
            ),
          )
          .asMinutes(),
      )

      waypoint.rem = moment
        .utc(
          moment
            .duration(card[destinationIndex].eta.diff(ata))
            .asMilliseconds(),
        )
        .format('HH:mm')
      waypoint.abo = Math.abs(Number(afob - offFuel).toFixed(1))
      waypoint.burn = Number(Number(tbo) - Number(waypoint.abo)).toFixed(1)
      waypoint.tanks = Number(frmg - afob).toFixed(1)
      waypoint.clf = Number(Number(afob) - Number(dstn)).toFixed(1)
      waypoint.diffElfClf = Number(clf - estLandingFuel).toFixed(1)
      return waypoint
    }

    if (waypoint.waypointType === 'ETP') {
      console.log('Calculate the ETP score')
    }

    return waypoint
  })

  // down here somewhere

  return prevState
}

export default CalculateScore
