const etpParser = (releaseArray) => {
  releaseArray = releaseArray.reduce((acc, line) => {
    let { str } = line
    if (str.match(/KALITTA BRIEF PAGE \d+ OF \d+/)) {
      //console.log(str)
      return acc
    }
    if (str.match(/PAGE \d+ OF \d+/)) {
      //console.log(str)
      return acc
    }
    acc.push(line)
    return acc
  }, [])
    
  return {
    allNormalEtp: getAllNormal(releaseArray),
    depressedEtp: getDepressurized(releaseArray),
    engineOutEtp: getEngineOut(releaseArray),
  }
}

const getAllNormal = (releaseArray) => {
  let start = releaseArray.findIndex((line, index) => {
    return line.str.includes('M86 ALL ENGINES/ ALL NORMAL')
  })

  if (start > 0) {
    let end = start + 7
    let allNormalEtp = releaseArray.slice(start, end)
//HKD TO ANC POSN N52 18.2 E170 38.5 GWT 701928LBS LVLOF 29
    return {
      before: allNormalEtp[1].str.slice(4, 8),
      after: allNormalEtp[2].str.slice(4, 8),
      time: allNormalEtp[4].str.slice(
        allNormalEtp[4].str.length - 5,
        allNormalEtp[4].str.length,
      ),
      burnOff: Number(allNormalEtp[4].str.slice(18, 25).trim()) / 1000,
      position: allNormalEtp[3].str.slice(16,35).trim(),
      levelOff: allNormalEtp[3].str.slice(56,58),
      gw: allNormalEtp[3].str.slice(40,46)
    }
  } else {
    return {
      before: '',
      after: '',
      time: 0,
      burnOff: 0,
      position: null,
      levelOff: 0,
      gw: 0
    }
  }
}

const getDepressurized = (releaseArray) => {
  let start = releaseArray.findIndex((line, index) => {
    return line.str.includes('LRC ALL ENGINES DEPRESSURIZED')
  })
  if (start > 0) {
    let end = start + 7
    let depressedEtp = releaseArray.slice(start, end)

    return {
      before: depressedEtp[1].str.slice(4, 8),
      after: depressedEtp[2].str.slice(4, 8),
      time: depressedEtp[4].str.slice(
        depressedEtp[4].str.length - 5,
        depressedEtp[4].str.length,
      ),
      burnOff: Number(depressedEtp[4].str.slice(18, 25).trim()) / 1000,
      position: depressedEtp[3].str.slice(16,35).trim(),
      levelOff: depressedEtp[3].str.slice(56,58),
      gw: depressedEtp[3].str.slice(40,46)
    }
  } else {
    return {
      before: '',
      after: '',
      time: 0,
      burnOff: 0,
      position: null,
      levelOff: 0,
      gw: 0
    }
  }
}

const getEngineOut = (releaseArray) => {
  let start = releaseArray.findIndex((line, index) => {
    return line.str.includes('2EO DRIFTDOWN LRC')
  })
  if (start > 0) {
    let end = start + 7
    let engineOutEtp = releaseArray.slice(start, end)
    return {
      before: engineOutEtp[1].str.slice(4, 8),
      after: engineOutEtp[2].str.slice(4, 8),
      time: engineOutEtp[4].str.slice(
        engineOutEtp[4].str.length - 5,
        engineOutEtp[4].str.length,
      ),
      burnOff: Number(engineOutEtp[4].str.slice(18, 25).trim()) / 1000,
      position: engineOutEtp[3].str.slice(16,35).trim(),
      levelOff: engineOutEtp[3].str.slice(56,58),
      gw: engineOutEtp[3].str.slice(40,46)
    }
  } else {
    return {
      before: '',
      after: '',
      time: 0,
      burnOff: 0,
      position: null,
      levelOff: 0,
      gw: 0
    }
  }
}

export default etpParser
