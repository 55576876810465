import React from 'react'
import { Card, CardContent, Grid, Typography, Hidden } from '@material-ui/core'
import Origin from './Origin'
// import { Virtuoso } from 'react-virtuoso'
import Waypoint from './Waypoint'
import Fir from './Fir'
import Etp from './Etp'
import Destination from './Destination'
import Alternate from './Alternate'

/* Hey Corey when you are trying to debug this crazy shit when operations decides to change the release format remeber to start by 
commenting out the array.push methods in each if statement below to narrow down where the parign error is FYI buddy ;-)  PAST COREY */

const ScoreCard = ({
  openScoreDialog,
  handleOffTimeOrFuelBlur,
  positionIndex,
  rows,
  offFuel,
  offTime,
  estLandingFuel,
  handleAlertOpen,
}) => {
  return rows.reduce((array, waypoint, i) => {
    let { waypointType } = waypoint
    let highlighted = i <= positionIndex ? true : false
    let scrollPoint = 'dontScollHere'

    if (i === positionIndex) {
      scrollPoint = 'scrollHere'
    }

    if (waypointType === 'origin') {
      array.push(
        <Origin
          key={i}
          handleOffTimeOrFuelBlur={handleOffTimeOrFuelBlur}
          offTime={offTime}
          offFuel={offFuel}
          data={waypoint}
          index={i}
          positionIndex={positionIndex}
        />,
      )
    }
    if (waypointType === 'waypoint') {
      array.push(
        <Waypoint
          highlighted={highlighted}
          key={i}
          index={i}
          offTime={offTime}
          offFuel={offFuel}
          openScoreDialog={openScoreDialog}
          positionIndex={positionIndex}
          data={waypoint}
          handleAlertOpen={handleAlertOpen}
          estLandingFuel={estLandingFuel}
          scrollPoint={scrollPoint}
        />,
      )
    }
    if (waypointType === 'fir') {
      array.push(
        <Fir
          key={i}
          handleAlertOpen={handleAlertOpen}
          data={waypoint}
          highlighted={highlighted}
        />,
      )
    }
    if (waypointType === 'ETP') {
      array.push(
        <Etp
          key={i}
          handleAlertOpen={handleAlertOpen}
          data={waypoint}
          highlighted={highlighted}
        />,
      )
    }

    if (waypointType === 'destination') {
      array.push(
        <Destination
          highlighted={highlighted}
          key={i}
          index={i}
          offTime={offTime}
          offFuel={offFuel}
          openScoreDialog={openScoreDialog}
          positionIndex={positionIndex}
          data={waypoint}
          handleAlertOpen={handleAlertOpen}
          estLandingFuel={estLandingFuel}
        />,
      )
    }
    if (waypointType === 'alternate') {
      array.push(
        <Alternate
          highlighted={highlighted}
          key={i}
          index={i}
          offTime={offTime}
          offFuel={offFuel}
          openScoreDialog={openScoreDialog}
          positionIndex={positionIndex}
          data={waypoint}
          handleAlertOpen={handleAlertOpen}
          estLandingFuel={estLandingFuel}
        />,
      )
    }
    return array
  }, [])
}

export default ScoreCard
