import React from 'react'
import { Grid, Hidden, Typography, Card, CardContent } from '@material-ui/core'

const FixedLegend = () => {
  return (
    <Card
      style={{
        paddingTop: 70,
        position: 'fixed',
        width: '100%',
        zIndex: 15,
      }}
    >
      <CardContent style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography align="left" variant="body2">
              COORD
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography aling="left" variant="body2">
                  IDENT
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  DIST
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  MC
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  FL
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  WIND
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  CMP
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  TAS
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  TIME
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  ETA
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  ATA
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  TBO
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  FRMG
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography aling="cente" variant="body2"></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography aling="left" variant="body2">
                  FRQ
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  DTGO
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  MH
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2"></Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  W/S
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  OAT
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  GS
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  T/TME
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  REV
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  REM
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  ABO
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  AFOB
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  DSTN
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  &nbsp;
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  &#916; T
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  BURN
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography aling="center" variant="body2">
                  TANKS
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                LDF
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default FixedLegend
