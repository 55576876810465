import ReleaseParser from "./ReleaseParser";
import pdfjs from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"; // this is required do not delete

const HandelFileChange = (e, setScoreCard) => {
  let file = e.target.files[0];
  if (file) {
    let reader = new FileReader();
    reader.onload = async (event) => {
      let typedarray = new Uint8Array(reader.result);
      let releaseArray = await readPDF(typedarray);



      //console.log(JSON.stringify(ReleaseParser(releaseArray)))
      setScoreCard(ReleaseParser(releaseArray));

      // return the releaseArray to the global state of App
    };
    reader.readAsArrayBuffer(file);

  }
};

const readPDF = async (src) => {
  let fullRelease = [];
  const loadingTask = pdfjs.getDocument(src);
  const pdf = await loadingTask.promise;
  let maxPages = pdf.numPages;
  for (let i = 1; i < maxPages; i++) {
    let pageObject = await pdf.getPage(i);
    let { items } = await pageObject.getTextContent();
    fullRelease = fullRelease.concat(items);
  }
  return fullRelease;
};

export default HandelFileChange;
