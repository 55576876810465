import React, { Component } from 'react'
import {
  DialogContent,
  Typography,
  DialogTitle,
  Dialog,
  DialogActions,
  Button,
  Link,
} from '@material-ui/core'

class HelpDialog extends Component {
  handleClose = () => {
    this.props.onClose('helpDialog')
  }

  forceUpdate = () => {
    window.location.reload()
  }

  emailMe = (e) => {
    window.location.href = `mailto:cgrimes@kalittaair.com`
    this.handleClose()
  }

  render() {
    const version = 1.28
    let { scoreCardLength } = this.props

    let content

    if (scoreCardLength > 2) {
      content = (
        <>
          <Typography variant="subtitle2" gutterBottom>
            Disclaimer: Use at your own risk !!!!!
          </Typography>
          <Typography variant="body2" gutterBottom>
            This application is still a work in progress. It is only intended as
            a supplement to the actual flight release remeber to always double
            check values entered and recorded. As the old adage goes garbage in
            garbage out
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Release Notes: {version}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <ul>
              <li>
                After many requests the time annotations when scoring a waypoint
                now accrately reflect the GOM. (late to a point is + and early
                is a -)
              </li>
              <li>
                The crew communication function has been removed due to issues
                with maintaining a current list of emails and security concerns.
                I am currently working on a more robust solution.
              </li>
              <li>
                Install this app function to make the the installation of the
                application more streamlined.
              </li>
            </ul>
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Instructions:
          </Typography>
          <Typography variant="body2" gutterBottom>
            To begin first load a flight release. Tap on the carrot icon in the
            upper right hand corner of the screen. Select the option that says
            parse release. The ipads file system should appear. Select a pdf
            flight release that has been downloaded to your ipad. Enter the off
            time and fuel and the application will automatically calculate each
            waypoints ETA and REV time. When performing a fuel score tap the
            '--:--' symbol for any waypoint. Then enter the ATA and AFOB in the
            dialog box and select done. The flight release annotations should
            then appear.
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Other Features:
          </Typography>
          <Typography variant="body2" gutterBottom>
            <ul>
              <li>Copy Jep FD Route to system Clipboard</li>
              <li>Quickly draft emails</li>
              <li>Display MELs and COIs</li>
              <li>Copy MEL ATA code to system Clipboard</li>
              <li>Copy Waypoint or Coordinates to system Clipboard</li>
              <li>Copy flight plan alternates to system Clipboard</li>
              <li>Highlighting of passed waypoints based on rev time</li>
            </ul>
          </Typography>

          <Typography variant="subtitle2" gutterBottom>
            Bug Reporting:
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you have questions or encounter problems please contact me via
            email. Please ensure to attach a copy of the release being used and
            a short description of the issue and a screen shot if necessary.
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Email: <Link onClick={this.emailMe}>cgrimes@kalittaair.com</Link>
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Happy Flying: Corey Grimes
          </Typography>
        </>
      )
    } else {
      content = (
        <>
          <Typography variant="subtitle2" gutterBottom>
            Instructions:
          </Typography>
          <Typography variant="body2" gutterBottom>
            To begin first load a flight release. Tap on the carrot icon in the
            upper right hand corner of the screen. Select the option that says
            parse release. The ipads file system should appear. Select a pdf
            flight release that has been downloaded to your ipad. Enter the off
            time and fuel and the application will automatically calculate each
            waypoints ETA and REV time. When performing a fuel score tap the
            '--:--' symbol for any waypoint. Then enter the ATA and AFOB in the
            dialog box and select done. The correct flight release annotations
            should then appear.
          </Typography>
        </>
      )
    }

    return (
      <Dialog
        aria-labelledby="simple-dialog-title"
        onClose={this.handleClose}
        open={this.props.open}
        ref={this.props.ref}
      >
        <DialogTitle id="simple-dialog-title">
          <Typography variant="h5" gutterBottom>
            Super Score
          </Typography>
          <Typography
            onClick={this.forceUpdate}
            variant="caption"
            display="block"
            gutterBottom
          >
            {`Version ${version}`}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: 10 }}>{content}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleClose}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default HelpDialog
