import React, { Component } from 'react'
import { KeyboardTimePicker } from '@material-ui/pickers'
import moment from 'moment'
class OffTime extends Component {
  state = { offTime: moment() }

  componentDidMount() {
    this.setState({ offTime: this.props.offTime })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.offTime !== prevProps.offTime) {
      this.setState({ offTime: this.props.offTime })
    }
  }

  onChange = (time) => {
    this.setState({ offTime: time })
  }

  onBlur = (e) => {
    let { offTime } = this.state
    if (offTime) {
      this.props.handleOffTimeOrFuelBlur(
        moment(offTime, 'HH:mm').clone(),
        'offTime',
      )
    }
  }

  onAccept = (time) => {
    this.props.handleOffTimeOrFuelBlur(time.clone(), 'offTime')
  }

  render() {
    return (
      <KeyboardTimePicker
        value={this.state.offTime}
        ampm={false}
        label="Off Time"
        inputVariant="outlined"
        onChange={this.onChange}
        onBlur={this.onBlur}
        size="small"
        onAccept={this.onAccept}
      />
    )
  }
}

export default OffTime
